<template>
  <div class="app-container">
    <img src="../assets/images/back.jpg" style="width:25%;height: 89vh; ">
    <img src="../assets/images/back.jpg" style="width:25%;height: 89vh; ">
    <img src="../assets/images/back.jpg" style="width:25%;height: 89vh; ">
    <img src="../assets/images/back.jpg" style="width:25%;height: 89vh; ">
  </div>
</template>

<script>


export default {
  name: 'Home',
  data() {
    return {

    }
  },
  methods:{

  }
}
</script>

<style scoped>
.app-container{
  width: 100%;
  overflow: hidden;
}
</style>
